var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{attrs:{"id":"app-bar","absolute":"","app":"","color":"transparent","flat":"","height":"75"}},[_c('v-btn',{staticClass:"mr-3",attrs:{"elevation":"1","fab":"","small":""},on:{"click":function($event){return _vm.setDrawer(!_vm.drawer)}}},[(_vm.value)?_c('v-icon',[_vm._v(" mdi-view-quilt ")]):_c('v-icon',[_vm._v(" mdi-dots-vertical ")])],1),_c('v-toolbar-title',{staticClass:"hidden-sm-and-down font-weight-light text-uppercase",domProps:{"textContent":_vm._s(_vm.$store.state.user.role + ' Dashboard')}}),_c('v-spacer'),_c('v-text-field',{staticStyle:{"max-width":"250px"},attrs:{"color":"secondary","label":'Search',"outlined":"","hide-details":"","dense":""},scopedSlots:_vm._u([(_vm.$vuetify.breakpoint.mdAndUp)?{key:"append-outer",fn:function(){return [_c('v-btn',{staticClass:"mt-n2",attrs:{"elevation":"1","fab":"","small":""}},[_c('v-icon',[_vm._v("mdi-magnify")])],1)]},proxy:true}:null],null,true)}),_c('v-menu',{attrs:{"bottom":"","left":"","offset-y":"","origin":"top right","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"min-width":"0","text":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c('v-list',{attrs:{"tile":false,"nav":""}},[_c('base-item',{attrs:{"item":{
          title: 'Profile',
          icon: 'mdi-account',
          to: { name: 'user-profile' },
        }}}),_c('base-item',{attrs:{"item":{
          title: 'Dashboard',
          icon: 'mdi-view-dashboard',
          to: { name: 'dashboard-home' },
        }}}),_c('base-item',{attrs:{"item":{
          title: 'Logout',
          icon: 'mdi-logout',
          to: { name: 'logout' },
        }}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }