var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-navigation-drawer',_vm._b({attrs:{"id":"core-navigation-drawer","dark":_vm.barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)',"expand-on-hover":_vm.expandOnHover,"right":_vm.$vuetify.rtl,"src":_vm.barImage,"mobile-breakpoint":"960","app":"","width":"260"},scopedSlots:_vm._u([{key:"img",fn:function(props){return [_c('v-img',_vm._b({attrs:{"gradient":("to bottom, " + _vm.barColor)}},'v-img',props,false))]}},{key:"append",fn:function(){return [_c('base-item',{attrs:{"item":{
        title: 'Logout',
        icon: 'mdi-logout',
        to: '/logout',
      }}})]},proxy:true}]),model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},'v-navigation-drawer',_vm.$attrs,false),[_c('v-list',[_c('v-list-item',{attrs:{"to":"/"}},[_c('v-img',{attrs:{"src":"/img/logo.png","contain":""}})],1),_c('v-divider',{staticClass:"ma-0 py-1"}),_c('base-item',{attrs:{"item":{
        title: 'Profile',
        icon: 'mdi-home',
        to: { name: 'user-profile' },
      }}})],1),_c('v-divider',{staticClass:"ma-0 pa-0"}),_c('v-list',{attrs:{"expand":"","nav":""}},[_vm._l((_vm.computedItems),function(item,i){return [(item.children)?_c('base-item-group',{key:("group-" + i),staticClass:"text-uppercase",attrs:{"item":item}}):_c('base-item',{key:("item-" + i),staticClass:"text-uppercase",attrs:{"item":item}})]})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }