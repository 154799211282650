<template>
  <v-app>
    <dashboard-core-app-bar />

    <dashboard-core-drawer />

    <dashboard-core-view />

    <!-- <dashboard-core-settings /> -->
    <notifications position="bottom center" group="msg" />
  </v-app>
</template>

<script>
import DashboardCoreAppBar from "./components/core/AppBar";
import DashboardCoreDrawer from "./components/core/Drawer";
import DashboardCoreView from "./components/core/View";
export default {
  name: "DashboardIndex",

  components: {
    DashboardCoreAppBar,
    DashboardCoreDrawer,
    DashboardCoreView,
  },

  data: () => ({
    expandOnHover: false,
  }),
  beforeCreate() {
    let chatBox = document.querySelector("#chat-widget-container");
    if (chatBox) {
      chatBox.style.visibility = "hidden";
      chatBox.style.display = "none";
    }
  },
  beforeDestroy() {
    let chatBox = document.querySelector("#chat-widget-container");
    if (chatBox) {
      chatBox.style.visibility = "visible";
      chatBox.style.display = "block";
    }
  },
};
</script>
